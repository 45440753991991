const apiContext = import.meta.env.VITE_APP_APIM_CONTEXT;

export const SERVICES = {
  ADMIN: `${apiContext}/bms3-admin-service`,
  CONFIG: `${apiContext}/bms3-config-service`,
  DOCUMENT: `${apiContext}/bms3-document-service`,
  INSPECTION: `${apiContext}/bms3-inspection-service`,
  REPORT: `${apiContext}/bms3-report-service`,
  RISK_SCORE: `${apiContext}/bms3-risk-score-service`,
  STRUCTURE: `${apiContext}/bms3-structure-service`,
  SPN: `${apiContext}/bms3-spn-service`,
};

export const CONFIG_ENDPOINT = {
  COUNTIES: `${SERVICES.CONFIG}/api/counties`,
  DISTRICTS: `${SERVICES.CONFIG}/api/districts`,
  ENV_CONFIG_VALUES: `${SERVICES.CONFIG}/api/envConfigValues`,
  MUNICIPALITIES: `${SERVICES.CONFIG}/api/municipalities`,
  REFERENCES: `${SERVICES.CONFIG}/api/references`,
  DEPARTMENT_STRUCTURE_TYPE_DESCRIPTIONS: `${SERVICES.CONFIG}/api/departmentStructureTypeDescriptions`,
  METRIC_CONVERSIONS: `${SERVICES.CONFIG}/api/metricConversions`,
  ELEMENT_DEFINITIONS: `${SERVICES.CONFIG}/api/elementDefinitions`,
  ELEMENT_CHILDREN: `${SERVICES.CONFIG}/api/elementChildren`,
  ELEMENT_STATE_DEFINITIONS: `${SERVICES.CONFIG}/api/stateDefinitions`,
  SCOUR_CRITICAL_BRIDGE_INDICATORS: `${SERVICES.CONFIG}/api/scourCriticalBridgeIndicators`,
  USERS: `${SERVICES.CONFIG}/api/users`,
  DOC_TYPES: `${SERVICES.CONFIG}/api/documentTypes`,
  PING_ACTUATOR: `${SERVICES.CONFIG}/actuator`,
  FLEX_ACTIONS: `${SERVICES.CONFIG}/api/flexActions`,
  BRIDGE_ACTIONS: `${SERVICES.CONFIG}/api/actions`,
  CODE_FILTERS: `${SERVICES.CONFIG}/api/codeFilters`,
  ORGANIZATIONS: `${SERVICES.CONFIG}/api/businessPartner`,
  WEBUSERS: `${SERVICES.CONFIG}/api/bms2WebUser`,
  NBIS_BRIDGE_INSPECTORS: `${SERVICES.CONFIG}/api/nbisBridgeInspectors`,
  NBIS_NSTM_BRIDGE_INSPECTORS: `${SERVICES.CONFIG}/api/nbisNSTMBridgeInspectors`,
  NBIS_TUNNEL_INSPECTORS: `${SERVICES.CONFIG}/api/nbisTunnelInspectors`,
  INSPECTORS: `${SERVICES.CONFIG}/api/inspectors`,
  UNDERWATER_DIVERS: `${SERVICES.CONFIG}/api/underwaterDivers`,
};

export const DOCUMENT_ENDPOINT = `${SERVICES.DOCUMENT}/api/documents`;
export const SUBMIT_PDF_REPORT_ENDPOINT = `${SERVICES.DOCUMENT}/api/documents/uploadD450/brkey/{brkey}`;

export const DOCUMENT_SERVICE_ENDPOINT = {
  UPLOAD_BPR_PHOTO: `${SERVICES.DOCUMENT}/api/documents/uploadBPRPhoto/brkey/{brkey}`,
  GET_DOCUMENTS_FROM_ECS: `${SERVICES.DOCUMENT}/api/documents/brkey/{brkey}`,
  GET_SINGLE_DOCUMENT_FROM_ECS: `${SERVICES.DOCUMENT}/api/documents/{docId}`,
};

export const INSPECTION_ENDPOINT = {
  SUBMIT_INSPECTION: `${SERVICES.INSPECTION}/api/inspections`,
  VALIDATE_INSPECTION: `${SERVICES.INSPECTION}/api/inspections/validate`,
  SYNC_INSPECTION: `${SERVICES.INSPECTION}/api/inspections/data`,
  LOAD_LATEST_INSPECTION: `${SERVICES.INSPECTION}/api/inspections/structures/{brkey}/latest`,
  GET_INSPECTIONS: `${SERVICES.INSPECTION}/api/inspections/structures/{brkey}`,
  LOAD_INSPECTION: `${SERVICES.INSPECTION}/api/inspections/structures/{brkey}/inspections/{inspkey}`,
  GET_INSP_KEYFIELDS: `${SERVICES.INSPECTION}/api/inspections/structures/{brkey}/inspections/{inspkey}/keyfields`,
};

export const STRUCTURE_ENDPOINT = {
  SEARCH_BY_BMSID: `${SERVICES.STRUCTURE}/api/structures/search/bmsid`,
  SEARCH_BY_BRKEY: `${SERVICES.STRUCTURE}/api/structures/search/brkey`,
  SEARCH_BY_PARAMETER: `${SERVICES.STRUCTURE}/api/structures/search/parameter`,
  GET_ROUTES: `${SERVICES.STRUCTURE}/api/structures/routes`,
  GET_AGREEMENT_CONTRACTS: `${SERVICES.STRUCTURE}/api/structures/agreementContracts`,
  GET_REPORT_GROUP_BY_COUNTY: `${SERVICES.STRUCTURE}/api/structures/reportGroups`,
  GET_PROBLEM_REPORTS_BY_BRKEY: `${SERVICES.STRUCTURE}/api/problemReports/brkey/{brkey}`,
  GET_PROBLEM_REPORTS_BY_BMSID: `${SERVICES.STRUCTURE}/api/problemReports/bmsId/{bmsId}`,
  GET_PROBLEM_REPORTS_BRIDGE_INFORMATION: `${SERVICES.STRUCTURE}/api/problemReports/bprBridgeInformation/{brkey}`,
  SAVE_PROBLEM_REPORT: `${SERVICES.STRUCTURE}/api/problemReports`,
  DELETE_PROBLEM_REPORT: `${SERVICES.STRUCTURE}/api/problemReports`,
  GET_STRUCTURE_META_DATA_BY_BRKEY: `${SERVICES.STRUCTURE}/api/structures/structureMetaData/brkey/{brkey}`,
  GET_STRUCTURE_META_DATA_BY_BMSID: `${SERVICES.STRUCTURE}/api/structures/structureMetaData/bmsId/{bmsId}`,
  GET_STRUCTURE_SEARCH: `${SERVICES.STRUCTURE}/api/search/structureSearch?size={pageSize}&sort={sortKey},ASC&page={pageNumber}`,
  GET_STRUCTURE_SEARCH_FIELDS: `${SERVICES.STRUCTURE}/api/search/fields`,
  GET_STRUCTURE_SEARCH_FIELD_LOOKUP_VALUES: `${SERVICES.STRUCTURE}/api/search/refValues/{paramId}`,
  GET_STRUCTURE_SEARCH_OPERATORS: `${SERVICES.STRUCTURE}/api/search/operators`,
  GET_STRUCTURE_SEARCH_QUERIES: `${SERVICES.STRUCTURE}/api/search/queries`,
  POST_STRUCTURE_SEARCH_QUERIES: `${SERVICES.STRUCTURE}/api/search/queries`,
  DELETE_STRUCTURE_SEARCH_QUERIES: `${SERVICES.STRUCTURE}/api/search/queries/{queryValue}`,
};

export const ADMIN_ENDPOINT = {
  SAVE_POUCHDB_USER: `${SERVICES.ADMIN}/api/pouchDBUser`,
  GET_POUCHDB_USER: `${SERVICES.ADMIN}/api/pouchDBUser/{userId}`,
  BB_MESSAGE: `${SERVICES.ADMIN}/api/bbMessages/{bbMessageId}`,
  GET_THIN_BB_MESSAGES: `${SERVICES.ADMIN}/api/bbMessages/thinMessages`,
  SAVE_BB_MESSAGE: `${SERVICES.ADMIN}/api/bbMessages`,
  GET_EXTERNAL_BB_MESSAGES: `${SERVICES.ADMIN}/api/external/bbMessages/thinMessages`,
  EXTERNAL_BB_MESSAGE: `${SERVICES.ADMIN}/api/external/bbMessages/{bbMessageId}`,
  GET_LINKS: `${SERVICES.ADMIN}/api/links`,
  UPDATE_LINKS: `${SERVICES.ADMIN}/api/links`,
  GET_LINKS_EXTERNAL: `${SERVICES.ADMIN}/api/external/links`,
  GET_BRIDGE_ASSIGNMENT_BY_BRKEY: `${SERVICES.ADMIN}/api/bridgeAssignment/brkey/{brkey}`,
  GET_BRIDGE_ASSIGNMENT_BY_BPID: `${SERVICES.ADMIN}/api/bridgeAssignment/bpId/{bpId}`,
  PUT_BRIDGE_ASSIGNMENT: `${SERVICES.ADMIN}/api/bridgeAssignment/`,
};

export const INVENTORY_ENDPOINT = {
  GET_RISK_SCORE_BY_BRKEY: `${SERVICES.RISK_SCORE}/api/riskScores/brkey/{brkey}`,
  GET_RISK_SCORE_BY_BMSID: `${SERVICES.RISK_SCORE}/api/riskScores/bmsid/{bmsId}`,
  UPDATE_RISK_ASSESSMENT_COMMENT: `${SERVICES.RISK_SCORE}/api/riskScores/details`,
  GET_STRUCTURE_IDENTIFICATION_BY_BRKEY: `${SERVICES.STRUCTURE}/api/StructureIdentification/brkey/{brkey}`,
  GET_THIN_STRUCTURE_IDENTIFICATION_BY_BRKEY: `${SERVICES.STRUCTURE}/api/StructureIdentification/thin/brkey/{brkey}`,
  UPDATE_STRUCTURE_IDENTIFICATION: `${SERVICES.STRUCTURE}/api/StructureIdentification`,
  GET_STRUCTURE_COMPOSITION_BY_BRKEY: `${SERVICES.STRUCTURE}/api/structureComposition/brkey/{brkey}`,
  UPDATE_STRUCTURE_COMPOSITION: `${SERVICES.STRUCTURE}/api/structureComposition`,
};

export const REPORTS_ENDPOINT = {
  GET_REPORTS: `${SERVICES.REPORT}/api/reports`,
  GET_REPORT: `${SERVICES.REPORT}/api/reports/powerBIReport/{bmsReportId}`,
  UPDATE_REPORTS: `${SERVICES.REPORT}/api/reports/version`,
};

export const RISK_SCORE_SEARCH_ENDPOINT = {
  GET_BASELINE_DATES: `${SERVICES.RISK_SCORE}/api/search/baselines`,
  RISK_SCORE_SEARCH_QUERIES: `${SERVICES.RISK_SCORE}/api/search/queries`,
  SEARCH_RISK_SCORE: `${SERVICES.RISK_SCORE}/api/search/riskScoreSearch`,
  RISK_SCORE_SEARCH_QUERY: `${SERVICES.RISK_SCORE}/api/search/queries/{queryId}`,
};

export const SPN_ENDPOINT = {
  GET_STRUCTURE_PLAN_BY_SL_NUMBER: `${SERVICES.SPN}/api/detail/slNumber`,
  GET_STRUCTURE_PLAN_BY_STRUCTURE_REQUEST_ID: `${SERVICES.SPN}/api/detail/structureReqId/{structureReqId}`,
  STRUCTURE_PLAN_DEFFAULT: `${SERVICES.SPN}/api/detail`,
  GET_STRUCTURE_PLANS_BY_PARAMS: `${SERVICES.SPN}/api/search/spnSearch`,
  GET_LINKED_STRUCTURE_META_DATA_BY_BRKEY: `${SERVICES.SPN}/api/detail/linkedStructureMetaData/brkey/{brkey}`,
};
