<template>
  <v-row>
    <ExpandCollapseToggle
      id="jointsPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel" />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Joint Conditions</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text eager>
          <v-form ref="jointConditions" v-model="valid">
            <LabelSelect
              :labelXl="3"
              :labelLg="4"
              :labelmd="4"
              :labelsm="5"
              :selectXl="3"
              :selectLg="4"
              :selectMd="5"
              :selectSm="6"
              id="jointRating"
              label="IJ01 Overall Joint Condition Rating"
              :refTable="REFERENCE_TABLE.JOINT_CONDITION_RATING"
              :isEditable="isEditable"
              :isRequired="isEditable"
              v-model="jointRating"
            ></LabelSelect>
            <v-row>
              <v-col sm="12">
                <CommentTypeTextField
                  label="Overall Joint Notes"
                  id="field_Overall_Joint_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.JOINTS_TINSP_COMMENTS
                  "
                  :readonly="!isEditable"
                  :counter="inspCommentMaxLength"
                  :maxlength="inspCommentMaxLength"
                />
              </v-col>
            </v-row>
            <div style="display: flex">
              <v-btn
                id="btn_addNewJoint"
                variant="outlined"
                class="my-2 d-inline"
                @click="addNewJoint"
                :disabled="!isEditable || expandedRows?.length > 0"
                >Add New Joint</v-btn
              >
              <div style="display: flex">
                <v-checkbox
                  class="pl-6 pt-2 d-inline showAllCheckbox"
                  id="checkbox_SHOW_REPLACEMENT_JOINTS"
                  hide-details
                  v-model="showAllJoints"
                  :disabled="
                    expandedRows?.length > 0 || !hasReplacedJointDetails()
                  "
                  @update:modelValue="
                    if (showAllJoints) {
                      getAllJointDetails();
                    } else {
                      getActiveJointDetails();
                    }
                  "
                >
                </v-checkbox>
                <span
                  class="pl-2 d-inline font-weight-bold"
                  style="padding-top: 12px"
                  >Show Replaced Joints</span
                >
              </div>
            </div>

            <v-row class="mb-8">
              <v-col sm="12">
                <v-data-table
                  id="joints-data-table"
                  v-model:page="pageInspection.page"
                  :items-per-page="pageInspection.itemsPerPage"
                  v-model:expanded="expandedRows"
                  :headers="headers"
                  :items="joints"
                  class="compactTable lessPaddingTableColumns"
                  v-model:sort-by="sortBy"
                  hide-default-footer
                  must-sort
                  show-expand
                  return-object
                  @update:sort-by="jointsFooter.setPageValue(1)"
                >
                  <template v-slot:[`header.data-table-expand`]="{ column }">
                    {{ column.title }}
                  </template>
                  <template v-slot:[`header.JOINT_KEY`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.RECORD_KEY`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.JOINT_TYPE`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.BEARING_TYPE`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.JOINT_LOCATION`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.JOINT_MOVEMENT`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template
                    v-slot:[`header.JOINT_DEBRIS_IMPACTION`]="{ column }"
                  >
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.JOINT_LEAKING`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.JOINT_DAMAGE`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.JOINT_COVERED`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.CONDITION_RATING`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>

                  <template v-slot:[`header.action`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td :class="rowClass(item) + ' alignCenter'">
                        <template
                          v-if="
                            expandedRows?.length == 0 || !anyJointDetailEditable
                          "
                        >
                          <button
                            v-if="!expandedRows.includes(item)"
                            @click="toggleRow(item)"
                            :id="`button_Chevron_Right_${item.UNIQUE_KEY}`"
                            :aria-describedby="`button_Chevron_Right_${item.UNIQUE_KEY}`"
                            :aria-label="`button_Chevron_Right_${item.UNIQUE_KEY}`"
                          >
                            <v-icon
                              :id="`icon_Chevron_Right_${item.UNIQUE_KEY}`"
                              icon="fa-solid fa-chevron-right"
                            ></v-icon>
                          </button>

                          <button
                            v-if="expandedRows.includes(item)"
                            @click="toggleRow(item)"
                            :id="`button_Chevron_Down_${item.UNIQUE_KEY}`"
                            :aria-describedby="`button_Chevron_Down_${item.UNIQUE_KEY}`"
                            :aria-label="`button_Chevron_Down_${item.UNIQUE_KEY}`"
                          >
                            <v-icon
                              :id="`icon_Chevron_Down_${item.UNIQUE_KEY}`"
                              icon="fa-solid fa-chevron-down"
                            ></v-icon>
                          </button>
                        </template>
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        {{ item.JOINT_KEY }}
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        {{ item.RECORD_KEY }}
                      </td>

                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>{{
                            item.JOINT_TYPE
                              ? configStore.getReferenceValue(
                                  REFERENCE_TABLE.JOINT_TYPE,
                                  item.JOINT_TYPE
                                )
                              : ""
                          }}</span>
                        </div>
                      </td>

                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>{{
                            jointLocations?.find(
                              (a) => a.value === item.JOINT_LOCATION
                            )?.title
                          }}</span>
                        </div>
                      </td>

                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>{{
                            item.JOINT_MOVEMENT
                              ? configStore.getReferenceValue(
                                  REFERENCE_TABLE.JOINT_MOVEMENT,
                                  item.JOINT_MOVEMENT
                                )
                              : ""
                          }}</span>
                        </div>
                      </td>

                      <td :class="rowClass(item)">
                        <v-checkbox
                          hide-details
                          :aria-label="`checkbox_${item.UNIQUE_KEY}_DebrisImpact`"
                          :true-value="'1'"
                          disabled
                          v-model="item.DEBRIS_IMPACT"
                          class="justify-center"
                        ></v-checkbox>
                      </td>
                      <td :class="rowClass(item)">
                        <v-checkbox
                          :aria-label="`checkbox_${item.UNIQUE_KEY}_Leaking`"
                          hide-details
                          :true-value="'1'"
                          disabled
                          v-model="item.LEAKING"
                          class="justify-center"
                        ></v-checkbox>
                      </td>
                      <td :class="rowClass(item)">
                        <v-checkbox
                          :aria-label="`checkbox_${item.UNIQUE_KEY}_Damage`"
                          hide-details
                          :true-value="'1'"
                          disabled
                          v-model="item.DAMAGE"
                          class="justify-center"
                        ></v-checkbox>
                      </td>
                      <td :class="rowClass(item)">
                        <v-checkbox
                          :aria-label="`checkbox_${item.UNIQUE_KEY}_Covered`"
                          hide-details
                          :true-value="'1'"
                          disabled
                          v-model="item.COVERED"
                          class="justify-center"
                        ></v-checkbox>
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>{{
                            item.CONDITION_RATING
                              ? configStore.getReferenceValue(
                                  REFERENCE_TABLE.JOINT_CONDITION_RATING,
                                  item.CONDITION_RATING
                                )
                              : ""
                          }}</span>
                        </div>
                      </td>

                      <td
                        :class="rowClass(item) + ' alignCenter'"
                        v-if="isEditable"
                      >
                        <div
                          style="white-space: nowrap"
                          v-if="
                            isExpanded(item) &&
                            anyJointDetailEditable &&
                            isEditable
                          "
                        >
                          <IconTooltip
                            id="icon_add"
                            icon="fa-solid fa-check"
                            :tooltip="item.isAdd ? 'Add' : 'Update'"
                            @click="saveDetail(item)"
                          />
                          <IconTooltip
                            id="icon_close"
                            icon="fa-solid fa-xmark"
                            tooltip="Close"
                            @click="closeDetail"
                          />
                        </div>
                        <div
                          style="white-space: nowrap"
                          v-else-if="
                            isEditable &&
                            (expandedRows?.length == 0 ||
                              !anyJointDetailEditable)
                          "
                        >
                          <IconTooltip
                            id="icon_edit"
                            icon="fa-solid fa-pencil"
                            tooltip="Edit"
                            :disabled="isNotCurrent(item)"
                            @click="editDetail(item)"
                          />
                          <IconTooltip
                            id="icon_replace"
                            icon="fa-solid fa-arrows-rotate"
                            tooltip="Replace"
                            :disabled="isNotCurrent(item)"
                            @click="replaceDetail(item)"
                          />
                          <IconTooltip
                            id="icon_delete"
                            icon="fa-solid fa-trash"
                            tooltip="Delete"
                            @click="deleteDetail(item)"
                            :disabled="isNotCurrent(item)"
                          />
                        </div>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-row="{ item }">
                    <td :colspan="headers.length">
                      <v-form
                        ref="detailForm"
                        v-model="validDetails"
                        class="mt-2"
                        v-if="$vuetify.display.lgAndUp"
                      >
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelText
                              :labelXl="5"
                              :labelLg="6"
                              :textXl="6"
                              :textLg="5"
                              id="jointKey"
                              label="IJ02 Joint Key"
                              v-model="item.JOINT_KEY"
                              @update:modelValue="changesDetected = true"
                            >
                            </LabelText>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelText
                              :labelXl="5"
                              :labelLg="6"
                              :textXl="6"
                              :textLg="5"
                              id="jointLength"
                              label="IJ08 Joint Length"
                              :allowCommas="false"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.JOINT_LENGTH"
                              @update:modelValue="changesDetected = true"
                              type="number"
                              :maxlength="10"
                              appendWith=" ft"
                            ></LabelText>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelText
                              :labelXl="5"
                              :labelLg="6"
                              :textXl="6"
                              :textLg="5"
                              id="recordKey"
                              label="IJ03 Record Key"
                              v-model="item.RECORD_KEY"
                              @update:modelValue="changesDetected = true"
                            >
                            </LabelText>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelCheckbox
                              :labelXl="5"
                              :labelLg="6"
                              id="impaction"
                              label="IJ09 Debris Impaction?"
                              :showColon="false"
                              editIndent
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.DEBRIS_IMPACT"
                              @update:modelValue="changesDetected = true"
                            ></LabelCheckbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelSelect
                              :labelXl="5"
                              :labelLg="6"
                              :selectXl="6"
                              :selectLg="5"
                              id="jointType"
                              label="IJ04 Joint Type"
                              :refTable="REFERENCE_TABLE.JOINT_TYPE"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.JOINT_TYPE"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelCheckbox
                              :labelXl="5"
                              :labelLg="6"
                              :showColon="false"
                              editIndent
                              id="leaking"
                              label="IJ10 Leaking?"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.LEAKING"
                              @update:modelValue="changesDetected = true"
                            ></LabelCheckbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelSelect
                              :labelXl="5"
                              :labelLg="6"
                              :selectXl="6"
                              :selectLg="5"
                              id="jointLocation"
                              label="IJ05 Joint Location"
                              :options="jointLocations"
                              :isEditable="
                                (isEditable &&
                                  !item.readOnly &&
                                  !item.isAdd &&
                                  !isReplacement(item)) ||
                                (isEditable && item.isAdd && !item.isReplacing)
                              "
                              v-model="item.JOINT_LOCATION"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelCheckbox
                              :labelXl="5"
                              :labelLg="6"
                              id="damage"
                              label="IJ11 Damage?"
                              :showColon="false"
                              editIndent
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.DAMAGE"
                              @update:modelValue="changesDetected = true"
                            ></LabelCheckbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelSelect
                              :labelXl="5"
                              :labelLg="6"
                              :selectXl="6"
                              :selectLg="5"
                              id="jointMovement"
                              label="IJ06 Joint Movement"
                              :refTable="REFERENCE_TABLE.JOINT_MOVEMENT"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.JOINT_MOVEMENT"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelCheckbox
                              class="py-0"
                              :labelXl="5"
                              :labelLg="6"
                              id="covered"
                              label="IJ12 Covered?"
                              :showColon="false"
                              editIndent
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.COVERED"
                              @update:modelValue="changesDetected = true"
                            ></LabelCheckbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" class="px-0 pb-3">
                            <LabelSelect
                              :labelXl="5"
                              :labelLg="6"
                              :selectXl="6"
                              :selectLg="5"
                              id="jointManufacturer"
                              label="IJ07 Joint Manufacturer"
                              :refTable="REFERENCE_TABLE.JOINT_MANUFACTURER"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.JOINT_MANUFACTURER"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="pt-1 pb-3 px-0">
                            <LabelSelect
                              :labelXl="5"
                              :labelLg="6"
                              :selectXl="6"
                              :selectLg="5"
                              id="jointCondRating"
                              label="IJ13 Condition Rating"
                              :refTable="REFERENCE_TABLE.JOINT_CONDITION_RATING"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.CONDITION_RATING"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="pl-0 pb-0">
                            <LabelText
                              labelXl="11"
                              labelLg="11"
                              textXl="12"
                              textLg="12"
                              type="textarea"
                              label="IJ19 Joint Notes"
                              id="jointNotes"
                              v-model="item.JOINT_COMMENT"
                              :isEditable="isEditable && !item.readOnly"
                              :counter="jointCommentMaxLength"
                              :maxlength="jointCommentMaxLength"
                              @update:modelValue="changesDetected = true"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelText
                              :labelXl="5"
                              :labelLg="6"
                              :textXl="6"
                              :textLg="5"
                              id="installYear"
                              label="IJ14 Extrusion Install Year"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.EXTRUSION_INSTALL_DATE"
                              type="number"
                              :maxlength="4"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return valueInRangeRule(
                                          value,
                                          1901,
                                          2999
                                        );
                                      },
                                    ]
                                  : []
                              "
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="px-0">
                            <LabelText
                              :labelXl="5"
                              :labelLg="6"
                              :textXl="6"
                              :textLg="5"
                              id="ecmsNo"
                              label="IJ16 ECMS No"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.ECMS_ID"
                              type="number"
                              :maxlength="6"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                            ></LabelText>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xl="6" lg="6" class="px-0 pb-2">
                            <LabelText
                              :labelXl="5"
                              :labelLg="6"
                              :textXl="6"
                              :textLg="5"
                              id="sealInstallYear"
                              label="IJ15 Seal Install Year"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.SEAL_INSTALL_DATE"
                              type="number"
                              :maxlength="4"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return valueInRangeRule(
                                          value,
                                          1901,
                                          2999
                                        );
                                      },
                                    ]
                                  : []
                              "
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="px-0 pb-2">
                            <LabelSelect
                              :labelXl="5"
                              :labelLg="6"
                              :selectXl="6"
                              :selectLg="5"
                              :isRequired="item.isReplacing"
                              id="replacementReason"
                              label="IJ17 Replacement Reason"
                              :refTable="
                                REFERENCE_TABLE.JOINT_REPLACEMENT_REASON
                              "
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.REPLACEMENT_REASON"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xl="12" lg="12">
                            <LabelText
                              labelXl="11"
                              labelLg="11"
                              textXl="12"
                              textLg="12"
                              type="textarea"
                              label="IJ18 Replacement Notes"
                              id="replacementNotes"
                              v-model="item.REPLACEMENT_COMMENT"
                              :isEditable="isEditable && !item.readOnly"
                              :counter="jointCommentMaxLength"
                              :maxlength="jointCommentMaxLength"
                              @update:modelValue="changesDetected = true"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-form
                        ref="detailForm"
                        v-model="validDetails"
                        class="mt-2"
                        v-else
                      >
                        <v-row>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="5"
                              :textMd="8"
                              :textSm="7"
                              id="jointKey"
                              label="IJ02 Joint Key"
                              v-model="item.JOINT_KEY"
                              @update:modelValue="changesDetected = true"
                            >
                            </LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="5"
                              :textMd="8"
                              :textSm="7"
                              id="recordKey"
                              label="IJ03 Record Key"
                              v-model="item.RECORD_KEY"
                              @update:modelValue="changesDetected = true"
                            >
                            </LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="5"
                              :selectMd="8"
                              :selectSm="7"
                              id="jointType"
                              label="IJ04 Joint Type"
                              :refTable="REFERENCE_TABLE.JOINT_TYPE"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.JOINT_TYPE"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="5"
                              :selectMd="8"
                              :selectSm="7"
                              id="jointLocation"
                              label="IJ05 Joint Location"
                              :options="jointLocations"
                              :isEditable="
                                (isEditable &&
                                  !item.readOnly &&
                                  !item.isAdd &&
                                  !isReplacement(item)) ||
                                (isEditable && item.isAdd && !item.isReplacing)
                              "
                              v-model="item.JOINT_LOCATION"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="5"
                              :selectMd="8"
                              :selectSm="7"
                              id="jointMovement"
                              label="IJ06 Joint Movement"
                              :refTable="REFERENCE_TABLE.JOINT_MOVEMENT"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.JOINT_MOVEMENT"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="5"
                              :selectMd="8"
                              :selectSm="7"
                              id="jointManufacturer"
                              label="IJ07 Joint Manufacturer"
                              :refTable="REFERENCE_TABLE.JOINT_MANUFACTURER"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.JOINT_MANUFACTURER"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="5"
                              :textMd="8"
                              :textSm="7"
                              id="jointLength"
                              label="IJ08 Joint Length"
                              :allowCommas="false"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.JOINT_LENGTH"
                              @update:modelValue="changesDetected = true"
                              type="number"
                              :maxlength="10"
                              appendWith=" ft"
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelCheckbox
                              :labelMd="4"
                              :labelSm="5"
                              id="impaction"
                              label="IJ09 Debris Impaction?"
                              :showColon="false"
                              editIndent
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.DEBRIS_IMPACT"
                              @update:modelValue="changesDetected = true"
                            ></LabelCheckbox>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelCheckbox
                              :labelMd="4"
                              :labelSm="5"
                              :showColon="false"
                              editIndent
                              id="leaking"
                              label="IJ10 Leaking?"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.LEAKING"
                              @update:modelValue="changesDetected = true"
                            ></LabelCheckbox>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelCheckbox
                              :labelMd="4"
                              :labelSm="5"
                              id="damage"
                              label="IJ11 Damage?"
                              :showColon="false"
                              editIndent
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.DAMAGE"
                              @update:modelValue="changesDetected = true"
                            ></LabelCheckbox>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelCheckbox
                              class="py-0"
                              :labelMd="4"
                              :labelSm="5"
                              id="covered"
                              label="IJ12 Covered?"
                              :showColon="false"
                              editIndent
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.COVERED"
                              @update:modelValue="changesDetected = true"
                            ></LabelCheckbox>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="5"
                              :selectMd="8"
                              :selectSm="7"
                              id="jointCondRating"
                              label="IJ13 Condition Rating"
                              :refTable="REFERENCE_TABLE.JOINT_CONDITION_RATING"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.CONDITION_RATING"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              labelMd="11"
                              labelSm="11"
                              textMd="12"
                              textSm="12"
                              type="textarea"
                              label="IJ19 Joint Notes"
                              id="jointNotes"
                              v-model="item.JOINT_COMMENT"
                              :isEditable="isEditable && !item.readOnly"
                              :counter="jointCommentMaxLength"
                              :maxlength="jointCommentMaxLength"
                              @update:modelValue="changesDetected = true"
                            />
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="5"
                              :textMd="8"
                              :textSm="7"
                              id="installYear"
                              label="IJ14 Extrusion Install Year"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.EXTRUSION_INSTALL_DATE"
                              type="number"
                              :maxlength="4"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return valueInRangeRule(
                                          value,
                                          1901,
                                          2999
                                        );
                                      },
                                    ]
                                  : []
                              "
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="5"
                              :textMd="8"
                              :textSm="7"
                              id="sealInstallYear"
                              label="IJ15 Seal Install Year"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.SEAL_INSTALL_DATE"
                              type="number"
                              :maxlength="4"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return valueInRangeRule(
                                          value,
                                          1901,
                                          2999
                                        );
                                      },
                                    ]
                                  : []
                              "
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="5"
                              :textMd="8"
                              :textSm="7"
                              id="ecmsNo"
                              label="IJ16 ECMS No"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.ECMS_ID"
                              type="number"
                              :maxlength="6"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="5"
                              :selectMd="8"
                              :selectSm="7"
                              :isRequired="item.isReplacing"
                              id="replacementReason"
                              label="IJ17 Replacement Reason"
                              :refTable="
                                REFERENCE_TABLE.JOINT_REPLACEMENT_REASON
                              "
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.REPLACEMENT_REASON"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              labelMd="12"
                              labelSm="12"
                              textMd="12"
                              textSm="12"
                              type="textarea"
                              label="IJ18 Replacement Notes"
                              id="replacementNotes"
                              v-model="item.REPLACEMENT_COMMENT"
                              :isEditable="isEditable && !item.readOnly"
                              :counter="jointCommentMaxLength"
                              :maxlength="jointCommentMaxLength"
                              @update:modelValue="changesDetected = true"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </td>
                  </template>
                  <template v-slot:bottom>
                    <DataTableFooter
                      ref="jointsFooter"
                      id="inspection_joints_footer"
                      :items="joints"
                      v-model="pageInspection"
                    ></DataTableFooter>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel> </v-expansion-panels
  ></v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>

<script>
import { ref, computed, watch, nextTick, onMounted } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";

import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import {
  INSPECTION_COMMENTS,
  STRUCTURE_UNIT_TYPE,
} from "@/constants/Inspections";
import { clone } from "@/util/clone";
import IconTooltip from "@/components/common/IconTooltip.vue";
import { useConfigStore } from "@/stores/config";
import { convertToInt } from "@/composables/util";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { LOGGER } from "@/util/logger";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import { BUTTONS } from "@/constants/CommonWebConstants";
import joint from "@/data/joint";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import { valueInRangeRule } from "@/composables/validationRules";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { scrollToDataTable } from "@/util/scrollToElement";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "InspectionJoints",

  data() {
    return {
      headers: [
        {
          key: "data-table-expand",
          width: "1%",
        },
        {
          line1: "IJ02",
          line2: "Joint",
          line3: "Key",
          key: "JOINT_KEY",
          sortable: true,
          width: "12%",
        },
        {
          line1: "IJ03",
          line2: "Record",
          line3: "Key",
          key: "RECORD_KEY",
          sortable: false,
        },
        {
          line1: "IJ04",
          line2: "Joint",
          line3: "Type",
          key: "JOINT_TYPE",
          sortable: false,
          width: "10%",
        },
        {
          line1: "IJ05",
          line2: "Joint",
          line3: "Location",
          key: "JOINT_LOCATION",
          sortable: false,
          width: "10%",
        },
        {
          line1: "IJ06",
          line2: "Joint",
          line3: "Movement",
          key: "JOINT_MOVEMENT",
          sortable: false,
          width: "10%",
        },
        {
          line1: "IJ09",
          line2: "Debris",
          line3: "Impaction?",
          key: "JOINT_DEBRIS_IMPACTION",
          sortable: false,
          width: "8%",
        },
        {
          line1: "IJ10",
          line2: "Leaking?",
          line3: null,
          key: "JOINT_LEAKING",
          sortable: false,
          width: "8%",
        },
        {
          line1: "IJ11",
          line2: "Damage?",
          line3: null,
          key: "JOINT_DAMAGE",
          sortable: false,
          width: "8%",
        },
        {
          line1: "IJ12",
          line2: "Covered?",
          line3: null,
          key: "JOINT_COVERED",
          sortable: false,
          width: "8%",
        },
        {
          line1: "IJ13",
          line2: "Condition",
          line3: "Rating",
          key: "CONDITION_RATING",
          sortable: true,
          width: "15%",
        },
        {
          line1: null,
          line2: "Action",
          line3: null,
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  setup() {
    const allPanels = [0];
    let panel = ref(allPanels);
    const inspectionStore = useInspectionStore();
    const snackbarStore = useSnackbarStore();
    let structure = computed(() => inspectionStore.selectedInspection);
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    const jointRating = computed({
      get: () => {
        return structure.value.T_Joints?.[0]?.OVERALL_COND_RATING;
      },
      set: (value) => {
        if (structure.value.T_Joints[0]) {
          structure.value.T_Joints[0].OVERALL_COND_RATING = value;
        } else {
          structure.value.T_Joints[0] = {
            OVERALL_COND_RATING: value,
            BRKEY: structure.value.Bridge.BRKEY,
            ID: null,
            INSPKEY: structure.value.InspEvnt.INSPKEY,
          };
        }
      },
    });
    const jointCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.JOINT_COMMENT_MAX_LENGTH
      );
    });
    const inspCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    let joints = ref([]);
    let expandedRows = ref([]);
    const configStore = useConfigStore();
    let snackBarId = ref("");
    const valid = ref(true);
    const jointConditions = ref(null);
    const getNewJoint = () => {
      return clone(joint);
    };
    let detailForm = ref(null);
    let validDetails = ref(false);
    let changesDetected = ref(false);
    let showAllJoints = ref(false);
    const closeConfirm = ref(null);
    const pageInspection = ref({
      itemsPerPage: 30,
      page: 1,
    });
    let jointsFooter = ref(null);
    let previousSort = ref([{ key: "JOINT_KEY", order: "asc" }]);
    let sortBy = ref([{ key: "JOINT_KEY", order: "asc" }]);
    async function validate() {
      jointConditions.value.validate();
    }
    onMounted(async () => {
      await validate();
    });
    const onRowExpand = (event) => {
      //on clicking on row expansion icon - set the data to readonly.
      event.data.readOnly = true;
    };

    const sort = (arr) => {
      arr.value = arr.value?.sort(function (a, b) {
        if (a.JOINT_KEY == b.JOINT_KEY) {
          return convertToInt(b.RECORD_KEY) - convertToInt(a.RECORD_KEY);
        } else {
          return a.JOINT_KEY - b.JOINT_KEY;
        }
      });
    };

    const isNotCurrent = (details) => {
      //check for replacements
      const maxRecordkey = Math.max(
        ...joints.value
          .filter((a) => a.JOINT_KEY == details.JOINT_KEY)
          .map((a) => a.RECORD_KEY)
      );
      return details.RECORD_KEY != maxRecordkey;
    };

    const isReplacement = (details) => {
      //check if if replacement
      const minRecordkey = Math.min(
        ...joints.value
          .filter((a) => a.JOINT_KEY == details.JOINT_KEY)
          .map((a) => a.RECORD_KEY)
      );
      return details.RECORD_KEY != minRecordkey;
    };

    const cloneCurrentInspectionJoints = () => {
      let jointsClone = clone(structure.value.T_Joints_Details);

      if (!jointsClone) {
        //remove null values from array(if any)
        jointsClone = jointsClone?.filter((a) => !!a);
      }

      const currentInspectionJointIndex = structure.value?.T_Joints?.findIndex(
        (a) => a?.INSPKEY == structure.value.InspEvnt.INSPKEY
      );

      jointsClone = jointsClone?.filter(
        (a) =>
          a?.ID == structure.value.T_Joints[currentInspectionJointIndex]?.ID
      );

      return jointsClone;
    };

    const getAllJointDetails = () => {
      expandedRows.value = [];
      joints.value = cloneCurrentInspectionJoints();
      //set unique key for toggle expansion
      for (const element of joints.value) {
        element.UNIQUE_KEY = element.JOINT_KEY + "-" + element.RECORD_KEY;
        element.readOnly = true;
      }
      sort(joints);
    };

    const toggleRow = (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (d) => d.RECORD_KEY == detail.RECORD_KEY
        );
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };

    const getActiveJointDetails = () => {
      expandedRows.value = [];
      joints.value = cloneCurrentInspectionJoints();
      let activeJoints = [];

      //set unique key for toggle expansion and check for original
      if (joints.value) {
        for (const element of joints.value) {
          if (element?.JOINT_KEY && element?.RECORD_KEY) {
            element.UNIQUE_KEY = element.JOINT_KEY + "-" + element.RECORD_KEY;
            element.readOnly = true;
            if (!isNotCurrent(element)) {
              activeJoints.push(element);
            }
          }
        }
      }
      joints.value = activeJoints;
      sort(joints);
    };

    const hasReplacedJointDetails = () => {
      const clonedJoints = cloneCurrentInspectionJoints();

      if (clonedJoints) {
        return clonedJoints.some(
          (element) =>
            element.JOINT_KEY && element.RECORD_KEY && isNotCurrent(element)
        );
      }
      showAllJoints.value = false;
      return false;
    };

    getActiveJointDetails();

    const addNewJoint = () => {
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.JOINTS_DATATABLE,
      });
      let newJoint = getNewJoint();
      if (structure.value.T_Joints[0]) {
        const currentInspectionJointIndex =
          structure.value?.T_Joints?.findIndex(
            (a) => a?.INSPKEY == structure.value.InspEvnt.INSPKEY
          );

        newJoint.ID = structure.value.T_Joints[currentInspectionJointIndex]?.ID;
      } else {
        newJoint.ID = null;
      }
      newJoint.JOINT_KEY = getNextJointKey() + 1;
      newJoint.RECORD_KEY = "1";
      newJoint.isAdd = true;
      newJoint.UNIQUE_KEY = newJoint.JOINT_KEY + "-" + newJoint.RECORD_KEY;
      newJoint.readOnly = false;
      joints.value.unshift(newJoint);
      previousSort.value = sortBy.value.map((x) => x);
      sortBy.value = [];
      expandedRows.value = [newJoint];
      scrollToDataTable("joints-data-table");
      jointsFooter.value?.setPageValue(1);

      nextTick(() => {
        detailForm.value.validate();
      });
    };

    const closeDetail = () => {
      if (isEditable.value && changesDetected.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              getJointDetails();
              expandedRows.value = [];
              inspectionStore.setAddingOrEditing(false);
              snackbarStore.removeMessage(snackBarId.value);
              changesDetected.value = false;
              sortBy.value = previousSort.value?.map((x) => x);
            }
          });
      } else {
        getJointDetails();
        expandedRows.value = [];
        inspectionStore.setAddingOrEditing(false);
        snackbarStore.removeMessage(snackBarId.value);
        sortBy.value = previousSort.value?.map((x) => x);
      }
    };

    const saveDetail = (detail) => {
      detailForm.value
        .validate()
        .then(() => {
          if (validDetails.value) {
            inspectionStore.setShowValidationSnackbar(false);
            if (!structure.value?.T_Joints_Details?.[0]) {
              //remove null values from array(if any)
              structure.value.T_Joints_Details =
                structure.value.T_Joints_Details?.filter((a) => !!a);
            }
            changesDetected.value = false;
            if (detail.isAdd) {
              if (!structure.value.T_Joints[0]) {
                const {
                  InspEvnt: { INSPKEY, BRKEY },
                } = structure.value;
                structure.value.T_Joints.push({
                  JOINT_OVERALL_COND_RATING: "",
                  BRKEY,
                  ID: null,
                  INSPKEY,
                });
              }
              structure.value.T_Joints_Details.push(
                convertToJointDetailsObject(detail)
              );
            } else {
              //update existing detail
              const updateIndex = structure.value.T_Joints_Details.findIndex(
                (a) =>
                  a.JOINT_KEY === detail.JOINT_KEY &&
                  a.RECORD_KEY === detail.RECORD_KEY
              );
              structure.value.T_Joints_Details.splice(
                updateIndex,
                1,
                convertToJointDetailsObject(detail)
              );
            }

            //if all required fields entered - close the expanded row
            expandedRows.value = [];
            inspectionStore.setAddingOrEditing(false);
            snackbarStore.removeMessage(snackBarId.value);
            getJointDetails();
            sortBy.value = previousSort.value?.map((x) => x);
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => {
          LOGGER.logException(err);
          changesDetected.value = false;
        });
    };

    const convertToJointDetailsObject = (details) => {
      let joinDetailsObject = getNewJoint();
      joinDetailsObject.ID = details.ID;
      joinDetailsObject.JOINT_KEY = details.JOINT_KEY;
      joinDetailsObject.RECORD_KEY = details.RECORD_KEY;
      joinDetailsObject.CONDITION_RATING = details.CONDITION_RATING;
      joinDetailsObject.COVERED = details.COVERED;
      joinDetailsObject.CREATEDATETIME = details.CREATEDATETIME;
      joinDetailsObject.CREATEUSERKEY = details.CREATEUSERKEY;
      joinDetailsObject.DAMAGE = details.DAMAGE;
      joinDetailsObject.DEBRIS_IMPACT = details.DEBRIS_IMPACT;
      joinDetailsObject.ECMS_ID = details.ECMS_ID;
      joinDetailsObject.EXTRUSION_INSTALL_DATE = details.EXTRUSION_INSTALL_DATE;
      joinDetailsObject.JOINT_COMMENT = details.JOINT_COMMENT;
      joinDetailsObject.JOINT_LENGTH = details.JOINT_LENGTH;
      joinDetailsObject.JOINT_LOCATION = details.JOINT_LOCATION;
      joinDetailsObject.JOINT_MANUFACTURER = details.JOINT_MANUFACTURER;
      joinDetailsObject.JOINT_MOVEMENT = details.JOINT_MOVEMENT;
      joinDetailsObject.JOINT_TYPE = details.JOINT_TYPE;
      joinDetailsObject.LEAKING = details.LEAKING;
      joinDetailsObject.MODTIME = new Date().toISOString();
      joinDetailsObject.REPLACEMENT_COMMENT = details.REPLACEMENT_COMMENT;
      joinDetailsObject.REPLACEMENT_REASON = details.REPLACEMENT_REASON;
      joinDetailsObject.SEAL_INSTALL_DATE = details.SEAL_INSTALL_DATE;
      joinDetailsObject.USERKEY = details.USERKEY;
      return joinDetailsObject;
    };

    const editDetail = (detail) => {
      previousSort.value = sortBy.value.map((x) => x);

      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.JOINTS_DATATABLE,
      });
      detail.readOnly = false;
      //if all required fields entered - close the expanded row
      expandedRows.value = [detail];
      nextTick(() => {
        detailForm.value?.validate();
      });
      sortBy.value = [];
      moveEditableElementToTop(detail);
      jointsFooter.value?.setPageValue(1);
    };

    const moveEditableElementToTop = (detail) => {
      const index = joints.value.findIndex(
        (a) =>
          a.JOINT_KEY === detail.JOINT_KEY && a.RECORD_KEY === detail.RECORD_KEY
      );
      let editableElement = joints.value[index];
      //then remove that element and use unshift
      joints.value.splice(index, 1);
      joints.value.unshift(editableElement);
    };

    const replaceDetail = (detail) => {
      previousSort.value = sortBy.value.map((x) => x);
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.JOINTS_DATATABLE,
      });
      let newJoint = getNewJoint();
      newJoint.isAdd = true;
      newJoint.isReplacing = true;
      newJoint.ID = detail.ID || null;
      newJoint.JOINT_KEY = detail.JOINT_KEY;
      newJoint.RECORD_KEY = (getNextRecordKey(detail.JOINT_KEY) + 1).toString();
      newJoint.UNIQUE_KEY = newJoint.JOINT_KEY + "-" + newJoint.RECORD_KEY;
      newJoint.JOINT_LOCATION = detail.JOINT_LOCATION;
      newJoint.readOnly = false;
      joints.value.push(newJoint);
      expandedRows.value = [newJoint];
      sort(joints);
      nextTick(() => {
        detailForm.value.validate();
      });
    };

    const deleteDetail = (detail) => {
      const deleteIndex = structure.value.T_Joints_Details.findIndex(
        (a) =>
          a.JOINT_KEY === detail.JOINT_KEY && a.RECORD_KEY === detail.RECORD_KEY
      );
      if (deleteIndex > -1) {
        structure.value.T_Joints_Details.splice(deleteIndex, 1);
        expandedRows.value = [];
        getJointDetails();
      }
    };

    const getNextJointKey = () => {
      if (joints.value) {
        return Math.max(
          ...joints.value.map((jointDetail) =>
            convertToInt(jointDetail?.JOINT_KEY ?? 0)
          ),
          0
        );
      } else {
        return 0;
      }
    };
    const getNextRecordKey = (jointKey) => {
      let jointDetails = joints.value.filter(
        (jointDetail) => jointDetail.JOINT_KEY == jointKey
      );
      return Math.max(
        ...jointDetails.map((jointDetail) =>
          convertToInt(jointDetail.RECORD_KEY)
        )
      );
    };

    const isExpanded = (details) => {
      return (
        expandedRows.value?.findIndex(
          (a) =>
            a.JOINT_KEY == details.JOINT_KEY &&
            a.RECORD_KEY === details.RECORD_KEY
        ) >= 0
      );
    };
    //exclude APRAS types
    const jointLocations = inspectionStore
      .getFilteredStructureUnits([STRUCTURE_UNIT_TYPE.APRAS_SPAN], false)
      ?.map((b) => ({
        title: b.STRUNITDESCRIPTION
          ? `${b.STRUNITDESCRIPTION}-${b.STRUNITLABEL}`
          : `${configStore.getReferenceValue(
              REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
              b.STRUNITTYPE,
              true
            )}-${b.STRUNITLABEL}`,
        value: b.STRUNITKEY.toString(),
      }))
      .sort((a, b) => a.title.localeCompare(b.title));

    const rowClass = (data) => {
      return isNotCurrent(data) && !data.isAdd ? "rowReplaced" : "rowCurrent";
    };

    const anyJointDetailEditable = computed(() => {
      return expandedRows.value?.find((element) => !element.readOnly);
    });

    const getJointDetails = () => {
      if (showAllJoints.value) {
        getAllJointDetails();
      } else {
        getActiveJointDetails();
      }
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };
    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.T_Joints,
        structure.value.T_Insp_Comment,
        structure.value.T_Joints_Details,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      isReplacement,
      panel,
      REFERENCE_TABLE,
      isEditable,
      structure,
      jointRating,
      INSPECTION_COMMENTS,
      joints,
      onRowExpand,
      expandedRows,
      addNewJoint,
      validDetails,
      isExpanded,
      configStore,
      closeDetail,
      saveDetail,
      editDetail,
      replaceDetail,
      deleteDetail,
      jointLocations,
      isNotCurrent,
      jointCommentMaxLength,
      inspCommentMaxLength,
      rowClass,
      anyJointDetailEditable,
      detailForm,
      changesDetected,
      showAllJoints,
      getAllJointDetails,
      getActiveJointDetails,
      closeConfirm,
      BUTTONS,
      inspectionStore,
      INSPECTION_MESSAGES,
      getJointDetails,
      toggleRow,
      hasReplacedJointDetails,
      valueInRangeRule,
      pageInspection,
      jointsFooter,
      sortBy,
      jointConditions,
      valid,
      validate,
      toggleAllPanels,
    };
  },
  components: {
    DataTableHeader,
    LabelSelect,
    CommentTypeTextField,
    LabelText,
    LabelCheckbox,
    IconTooltip,
    ConfirmDialog,
    DataTableFooter,
    ExpandCollapseToggle,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.compactTable :deep(.v-input__control) {
  display: block !important;
}
.alignCenter {
  text-align: center;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}
</style>
