<template>
  <v-row>
    <ExpandCollapseToggle
      id="bearingsPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel" />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Bearing Conditions</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text eager>
          <v-form ref="bearingConditions" v-model="valid">
            <LabelSelect
              :labelXl="3"
              :labelLg="4"
              :labelmd="4"
              :labelSm="5"
              :selectXl="3"
              :selectLg="4"
              :selectMd="5"
              :selectSm="6"
              id="bearingRating"
              label="IB01 Overall Bearing Condition Rating"
              :refTable="REFERENCE_TABLE.BEARING_CONDITION_RATING"
              :isEditable="isEditable"
              :isRequired="isEditable"
              v-model="bearingRating"
            ></LabelSelect>
            <v-row>
              <v-col sm="12">
                <CommentTypeTextField
                  label="Overall Bearing Notes"
                  id="field_Overall_Bearing_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.BEARING_OVERALL_COMMENTS
                  "
                  :readonly="!isEditable"
                  :counter="inspCommentMaxLength"
                  :maxlength="inspCommentMaxLength"
                />
              </v-col>
            </v-row>
            <div style="display: flex">
              <v-btn
                id="btn_addNewBearing"
                variant="outlined"
                class="my-2"
                @click="addNewBearing"
                :disabled="!isEditable || expandedRows?.length > 0"
                >Add New Bearing</v-btn
              >
              <div style="display: flex; align-items: center;">
                <v-checkbox
                  class="pl-6 d-inline showAllCheckbox"
                  id="checkbox_SHOW_REPLACEMENT_BEARINGS"
                  hide-details
                  :disabled="
                    expandedRows?.length > 0 || !hasReplacedBearingDetails()
                  "
                  v-model="showAllBearings"
                  @update:modelValue="
                    if (showAllBearings) {
                      getAllBearingDetails();
                    } else {
                      getActiveBearingDetails();
                    }
                  "
                >
                </v-checkbox>
                <span
                  class="d-inline font-weight-bold"
                  >Show Replaced Bearings</span
                >
              </div>
            </div>
            <v-row class="mb-8">
              <v-col sm="12">
                <v-data-table
                  id="bearings-data-table"
                  v-model:page="pageInspection.page"
                  :items-per-page="pageInspection.itemsPerPage"
                  v-model:expanded="expandedRows"
                  :headers="headers"
                  :items="bearings"
                  class="compactTable lessPaddingTableColumns"
                  v-model:sort-by="sortBy"
                  hide-default-footer
                  must-sort
                  show-expand
                  return-object
                  @update:sort-by="bearingsFooter.setPageValue(1)"
                >
                  <template v-slot:[`header.data-table-expand`]="{ column }">
                    {{ column.title }}
                  </template>
                  <template v-slot:[`header.BEARING_KEY`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.RECORD_KEY`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.BEARING_TYPE`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.BEARING_LOCATION`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.BEARING_COUNT`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.BEARING_MOVEMENT`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.BEARING_CORROSION`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template
                    v-slot:[`header.BEARING_ALIGNMENT_ISSUES`]="{ column }"
                  >
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template
                    v-slot:[`header.BEARING_ANCHOR_BOLT_ISSUES`]="{ column }"
                  >
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.BEARING_LOSS_OF_AREA`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.CONDITION_RATING`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>
                  <template v-slot:[`header.action`]="{ column }">
                    <DataTableHeader :column="column" :sortBy="sortBy" />
                  </template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td :class="rowClass(item) + ' alignCenter'">
                        <template
                          v-if="
                            expandedRows?.length == 0 ||
                            !anyBearingDetailEditable
                          "
                        >
                          <button
                            v-if="!expandedRows.includes(item)"
                            @click="toggleRow(item)"
                            :id="`button_Chevron_Right_${item.UNIQUE_KEY}`"
                            :aria-describedby="`button_Chevron_Right_${item.UNIQUE_KEY}`"
                            :aria-label="`button_Chevron_Right_${item.UNIQUE_KEY}`"
                          >
                            <v-icon
                              :id="`icon_Chevron_Right_${item.RECORD_KEY}`"
                              icon="fa-solid fa-chevron-right"
                            ></v-icon>
                          </button>

                          <button
                            v-if="expandedRows.includes(item)"
                            @click="toggleRow(item)"
                            :id="`button_Chevron_Down_${item.UNIQUE_KEY}`"
                            :aria-describedby="`button_Chevron_Down_${item.UNIQUE_KEY}`"
                            :aria-label="`button_Chevron_Down_${item.UNIQUE_KEY}`"
                          >
                            <v-icon
                              :id="`icon_Chevron_Down_${item.UNIQUE_KEY}`"
                              icon="fa-solid fa-chevron-down"
                            ></v-icon>
                          </button>
                        </template>
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        {{ item.BEARING_KEY }}
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        {{ item.RECORD_KEY }}
                      </td>

                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>{{
                            item?.BEARING_TYPE
                              ? configStore.getReferenceValue(
                                  REFERENCE_TABLE.BEARING_TYPE,
                                  item?.BEARING_TYPE
                                )
                              : ""
                          }}</span>
                        </div>
                      </td>

                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>
                            {{ getBearingLocation(item) }}
                          </span>
                        </div>
                      </td>

                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>
                            {{ item.BEARING_COUNT }}
                          </span>
                        </div>
                      </td>

                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>
                            {{
                              item?.BEARING_MOVEMENT
                                ? configStore.getReferenceValue(
                                    REFERENCE_TABLE.BEARING_MOVEMENT,
                                    item?.BEARING_MOVEMENT
                                  )
                                : ""
                            }}
                          </span>
                        </div>
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>
                            {{
                              item?.CORROSION
                                ? configStore.getReferenceValue(
                                    REFERENCE_TABLE.BEARING_CORROSION,
                                    item?.CORROSION
                                  )
                                : ""
                            }}
                          </span>
                        </div>
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>
                            {{
                              item?.ALLIGNMENT_ISSUES
                                ? configStore.getReferenceValue(
                                    REFERENCE_TABLE.BEARING_ALLIGNMENT_ISSUES,
                                    item?.ALLIGNMENT_ISSUES
                                  )
                                : ""
                            }}
                          </span>
                        </div>
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>
                            {{
                              item?.ANCHOR_BOLT
                                ? configStore.getReferenceValue(
                                    REFERENCE_TABLE.BEARING_ANCHOR_BOLT,
                                    item?.ANCHOR_BOLT
                                  )
                                : ""
                            }}
                          </span>
                        </div>
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>
                            {{
                              item?.BEARING_AREA
                                ? configStore.getReferenceValue(
                                    REFERENCE_TABLE.BEARING_LOSS_AREA,
                                    item?.BEARING_AREA
                                  )
                                : ""
                            }}
                          </span>
                        </div>
                      </td>
                      <td :class="rowClass(item) + ' alignCenter'">
                        <div class="ellipsisText">
                          <span>
                            {{
                              item?.CONDITION_RATING
                                ? configStore.getReferenceValue(
                                    REFERENCE_TABLE.BEARING_CONDITION_RATING,
                                    item?.CONDITION_RATING
                                  )
                                : ""
                            }}
                          </span>
                        </div>
                      </td>

                      <td
                        :class="rowClass(item) + ' alignCenter'"
                        v-if="isEditable"
                      >
                        <div
                          style="white-space: nowrap"
                          v-if="
                            isExpanded(item) &&
                            anyBearingDetailEditable &&
                            isEditable
                          "
                        >
                          <IconTooltip
                            :id="`icon_add_${item.UNIQUE_KEY}`"
                            icon="fa-solid fa-check"
                            :tooltip="item?.isAdd ? 'Add' : 'Update'"
                            @click="saveDetail(item)"
                          />
                          <IconTooltip
                            :id="`icon_close_${item.UNIQUE_KEY}`"
                            icon="fa-solid fa-xmark"
                            tooltip="Close"
                            @click="closeDetail"
                          />
                        </div>
                        <div
                          style="white-space: nowrap"
                          v-else-if="
                            isEditable &&
                            (expandedRows?.length == 0 ||
                              !anyBearingDetailEditable)
                          "
                        >
                          <IconTooltip
                            :id="`icon_edit_${item.UNIQUE_KEY}`"
                            icon="fa-solid fa-pencil"
                            tooltip="Edit"
                            @click="editDetail(item)"
                            :disabled="isNotCurrent(item)"
                          />
                          <IconTooltip
                            :id="`icon_replace_${item.UNIQUE_KEY}`"
                            icon="fa-solid fa-arrows-rotate"
                            tooltip="Replace"
                            @click="replaceDetail(item)"
                            :disabled="isNotCurrent(item)"
                          />
                          <IconTooltip
                            :id="`icon_delete_${item.UNIQUE_KEY}`"
                            icon="fa-solid fa-trash"
                            tooltip="Delete"
                            @click="deleteDetail(item)"
                            :disabled="isNotCurrent(item)"
                          />
                        </div>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-row="{ item }">
                    <td :colspan="headers.length">
                      <v-form
                        ref="detailForm"
                        v-model="validDetails"
                        class="mt-2"
                        v-if="$vuetify.display.lgAndUp"
                      >
                        <v-row>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelText
                              :labelXl="4"
                              :labelLg="6"
                              :textXl="7"
                              :textLg="5"
                              id="bearingKey"
                              label="IB02 Bearing Key"
                              v-model="item.BEARING_KEY"
                              @update:modelValue="changesDetected = true"
                            >
                            </LabelText>
                          </v-col>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelSelect
                              :labelXl="4"
                              :labelLg="6"
                              :selectXl="7"
                              :selectLg="5"
                              id="corrosion"
                              label="IB08 Corrosion?"
                              :showColon="false"
                              :refTable="REFERENCE_TABLE.BEARING_CORROSION"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.CORROSION"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelText
                              :labelXl="4"
                              :labelLg="6"
                              :textXl="7"
                              :textLg="5"
                              id="recordKey"
                              label="IB03 Record Key"
                              v-model="item.RECORD_KEY"
                              @update:modelValue="changesDetected = true"
                            >
                            </LabelText>
                          </v-col>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelSelect
                              :labelXl="4"
                              :labelLg="6"
                              :selectXl="7"
                              :selectLg="5"
                              id="alignIssues"
                              label="IB09 Alignment Issues?"
                              :showColon="false"
                              :refTable="
                                REFERENCE_TABLE.BEARING_ALLIGNMENT_ISSUES
                              "
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.ALLIGNMENT_ISSUES"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelSelect
                              :labelXl="4"
                              :labelLg="6"
                              :selectXl="7"
                              :selectLg="5"
                              id="bearingType"
                              label="IB04 Bearing Type"
                              :refTable="REFERENCE_TABLE.BEARING_TYPE"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.BEARING_TYPE"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelSelect
                              :labelXl="4"
                              :labelLg="6"
                              :selectXl="7"
                              :selectLg="5"
                              id="boltIssues"
                              label="IB10 Anchor Bolt Issues?"
                              :showColon="false"
                              :refTable="REFERENCE_TABLE.BEARING_ANCHOR_BOLT"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.ANCHOR_BOLT"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="6" xl="6" class="pa-0">
                            <v-row
                              ><v-col lg="9" xl="8" class="pa-0">
                                <LabelSelect
                                  :labelXl="6"
                                  :labelLg="8"
                                  :selectXl="6"
                                  :selectLg="4"
                                  id="bearingLocation"
                                  label="IB05 Bearing Location"
                                  :options="bearingLocations"
                                  :isEditable="
                                    (isEditable &&
                                      !item.readOnly &&
                                      !item.isAdd &&
                                      !isReplacement(item)) ||
                                    (isEditable &&
                                      item.isAdd &&
                                      !item.isReplacing)
                                  "
                                  v-model="item.BEARING_LOCATION"
                                  isRequired
                                  @update:modelValue="changesDetected = true"
                                ></LabelSelect>
                              </v-col>
                              <v-col lg="3" xl="4" class="pa-0">
                                <LabelSelect
                                  :selectXl="9"
                                  :selectLg="8"
                                  id="bearingSubUnit"
                                  label=""
                                  :refTable="
                                    REFERENCE_TABLE.BEARING_SUBSTRUCTURE_UNIT
                                  "
                                  :isEditable="
                                    (isEditable &&
                                      !item.readOnly &&
                                      !item.isAdd &&
                                      !isReplacement(item)) ||
                                    (isEditable &&
                                      item.isAdd &&
                                      !item.isReplacing)
                                  "
                                  v-model="item.BEARING_SUBSTR_UNIT"
                                  isRequired
                                  @update:modelValue="changesDetected = true"
                                ></LabelSelect>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelSelect
                              :labelXl="4"
                              :labelLg="6"
                              :selectXl="7"
                              :selectLg="5"
                              id="bearingAreaLoss"
                              label="IB11 Loss of Bearing Area?"
                              :showColon="false"
                              :refTable="REFERENCE_TABLE.BEARING_LOSS_AREA"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.BEARING_AREA"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelText
                              :labelXl="4"
                              :labelLg="6"
                              :textXl="7"
                              :textLg="5"
                              id="bearingCount"
                              label="IB06 Bearing Count"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.BEARING_COUNT"
                              :isRequired="isEditable && !item.readOnly"
                              type="number"
                              maxlength="3"
                              @update:modelValue="changesDetected = true"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return valueInRangeRule(value, 1, 999);
                                      },
                                    ]
                                  : []
                              "
                            ></LabelText>
                          </v-col>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelSelect
                              :labelXl="4"
                              :labelLg="6"
                              :selectXl="7"
                              :selectLg="5"
                              id="bearingCondRating"
                              label="IB12 Condition Rating"
                              :refTable="
                                REFERENCE_TABLE.BEARING_CONDITION_RATING
                              "
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.CONDITION_RATING"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelSelect
                              :labelXl="4"
                              :labelLg="6"
                              :selectXl="7"
                              :selectLg="5"
                              id="bearingMovement"
                              label="IB07 Bearing Movement?"
                              :refTable="REFERENCE_TABLE.BEARING_MOVEMENT"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.BEARING_MOVEMENT"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="12" xl="12" class="pt-2 pl-1">
                            <LabelText
                              labelXl="11"
                              labelLg="12"
                              textXl="11"
                              textLg="12"
                              type="textarea"
                              label="IB17 Bearing Notes"
                              id="bearingNotes"
                              v-model="item.BEARING_COMMENT"
                              :isEditable="isEditable && !item.readOnly"
                              :counter="bearingCommentMaxLength"
                              :maxlength="bearingCommentMaxLength"
                              @update:modelValue="changesDetected = true"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelText
                              :labelXl="4"
                              :labelLg="6"
                              :textXl="7"
                              :textLg="5"
                              id="installYear"
                              label="IB13 Install Year"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.INSTALL_DATE"
                              type="number"
                              :maxlength="4"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return valueInRangeRule(
                                          value,
                                          1901,
                                          2999
                                        );
                                      },
                                    ]
                                  : []
                              "
                            ></LabelText>
                          </v-col>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelSelect
                              :labelXl="4"
                              :labelLg="6"
                              :selectXl="7"
                              :selectLg="5"
                              id="replacementReason"
                              label="IB15 Replacement Reason"
                              :isRequired="item.isReplacing"
                              :refTable="
                                REFERENCE_TABLE.BEARING_REPLACEMENT_REASON
                              "
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.REPLACEMENT_REASON"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="6" xl="6" class="pa-0">
                            <LabelText
                              :labelXl="4"
                              :labelLg="6"
                              :textXl="7"
                              :textLg="5"
                              id="ecmsId"
                              label="IB14 ECMS ID"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.ECMS_ID"
                              type="number"
                              :maxlength="6"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                            ></LabelText>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col lg="12" xl="12" class="pt-2 pl-1">
                            <LabelText
                              labelXl="11"
                              labelLg="11"
                              textXl="12"
                              textLg="12"
                              type="textarea"
                              label="IB16 Replacement Notes"
                              id="replacementNotes"
                              v-model="item.REPLACEMENT_COMMENT"
                              :isEditable="isEditable && !item.readOnly"
                              :counter="bearingCommentMaxLength"
                              :maxlength="bearingCommentMaxLength"
                              @update:modelValue="changesDetected = true"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-form
                        ref="detailForm"
                        v-model="validDetails"
                        class="mt-2"
                        v-else
                      >
                        <v-row>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="4"
                              :textSm="8"
                              :textMd="8"
                              id="bearingKey"
                              label="IB02 Bearing Key"
                              v-model="item.BEARING_KEY"
                              @update:modelValue="changesDetected = true"
                            >
                            </LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="4"
                              :textSm="8"
                              :textMd="8"
                              id="recordKey"
                              label="IB03 Record Key"
                              v-model="item.RECORD_KEY"
                              @update:modelValue="changesDetected = true"
                            >
                            </LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="4"
                              :selectMd="8"
                              :selectSm="8"
                              id="bearingType"
                              label="IB04 Bearing Type"
                              :refTable="REFERENCE_TABLE.BEARING_TYPE"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.BEARING_TYPE"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <v-row
                              ><v-col cols="8" class="pa-0">
                                <LabelSelect
                                  class="bearings-location"
                                  :labelMd="6"
                                  :labelSm="6"
                                  :selectMd="6"
                                  :selectSm="6"
                                  id="bearingLocation"
                                  label="IB05 Bearing Location"
                                  :options="bearingLocations"
                                  :isEditable="
                                    (isEditable &&
                                      !item.readOnly &&
                                      !item.isAdd &&
                                      !isReplacement(item)) ||
                                    (isEditable &&
                                      item.isAdd &&
                                      !item.isReplacing)
                                  "
                                  v-model="item.BEARING_LOCATION"
                                  isRequired
                                  @update:modelValue="changesDetected = true"
                                ></LabelSelect> </v-col
                              ><v-col cols="4" class="pa-0">
                                <LabelSelect
                                  :selectMd="12"
                                  :selectSm="12"
                                  id="bearingSubUnit"
                                  label=""
                                  :refTable="
                                    REFERENCE_TABLE.BEARING_SUBSTRUCTURE_UNIT
                                  "
                                  :isEditable="
                                    (isEditable &&
                                      !item.readOnly &&
                                      !item.isAdd &&
                                      !isReplacement(item)) ||
                                    (isEditable &&
                                      item.isAdd &&
                                      !item.isReplacing)
                                  "
                                  v-model="item.BEARING_SUBSTR_UNIT"
                                  isRequired
                                  @update:modelValue="changesDetected = true"
                                ></LabelSelect>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="4"
                              :textSm="5"
                              :textMd="4"
                              id="bearingCount"
                              label="IB06 Bearing Count"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.BEARING_COUNT"
                              :isRequired="isEditable && !item.readOnly"
                              type="number"
                              maxlength="3"
                              @update:modelValue="changesDetected = true"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return valueInRangeRule(value, 1, 999);
                                      },
                                    ]
                                  : []
                              "
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="4"
                              :selectMd="8"
                              :selectSm="8"
                              id="bearingMovement"
                              label="IB07 Bearing Movement?"
                              :refTable="REFERENCE_TABLE.BEARING_MOVEMENT"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.BEARING_MOVEMENT"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="4"
                              :selectMd="8"
                              :selectSm="8"
                              id="corrosion"
                              label="IB08 Corrosion?"
                              :showColon="false"
                              :refTable="REFERENCE_TABLE.BEARING_CORROSION"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.CORROSION"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>

                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="4"
                              :selectMd="8"
                              :selectSm="8"
                              id="alignIssues"
                              label="IB09 Alignment Issues?"
                              :showColon="false"
                              :refTable="
                                REFERENCE_TABLE.BEARING_ALLIGNMENT_ISSUES
                              "
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.ALLIGNMENT_ISSUES"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>

                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="4"
                              :selectMd="8"
                              :selectSm="8"
                              id="boltIssues"
                              label="IB10 Anchor Bolt Issues?"
                              :showColon="false"
                              :refTable="REFERENCE_TABLE.BEARING_ANCHOR_BOLT"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.ANCHOR_BOLT"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>

                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="4"
                              :selectMd="8"
                              :selectSm="8"
                              id="bearingAreaLoss"
                              label="IB11 Loss of Bearing Area?"
                              :showColon="false"
                              :refTable="REFERENCE_TABLE.BEARING_LOSS_AREA"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.BEARING_AREA"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>

                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="4"
                              :selectMd="8"
                              :selectSm="8"
                              id="bearingCondRating"
                              label="IB12 Condition Rating"
                              :refTable="
                                REFERENCE_TABLE.BEARING_CONDITION_RATING
                              "
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.CONDITION_RATING"
                              isRequired
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>

                          <v-col cols="12" class="pa-0">
                            <LabelText
                              labelMd="12"
                              labelSm="12"
                              textMd="12"
                              textSm="12"
                              type="textarea"
                              label="IB17 Bearing Notes"
                              id="bearingNotes"
                              v-model="item.BEARING_COMMENT"
                              :isEditable="isEditable && !item.readOnly"
                              :counter="bearingCommentMaxLength"
                              :maxlength="bearingCommentMaxLength"
                              @update:modelValue="changesDetected = true"
                            />
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="4"
                              :textSm="5"
                              :textMd="4"
                              id="installYear"
                              label="IB13 Install Year"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.INSTALL_DATE"
                              type="number"
                              :maxlength="4"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                              :rules="
                                isEditable
                                  ? [
                                      (value) => {
                                        return valueInRangeRule(
                                          value,
                                          1901,
                                          2999
                                        );
                                      },
                                    ]
                                  : []
                              "
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              :labelMd="4"
                              :labelSm="4"
                              :textSm="5"
                              :textMd="4"
                              id="ecmsId"
                              label="IB14 ECMS ID"
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.ECMS_ID"
                              type="number"
                              :maxlength="6"
                              :showComma="false"
                              @update:modelValue="changesDetected = true"
                            ></LabelText>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelSelect
                              :labelMd="4"
                              :labelSm="4"
                              :selectMd="8"
                              :selectSm="8"
                              id="replacementReason"
                              label="IB15 Replacement Reason"
                              :isRequired="item.isReplacing"
                              :refTable="
                                REFERENCE_TABLE.BEARING_REPLACEMENT_REASON
                              "
                              :isEditable="isEditable && !item.readOnly"
                              v-model="item.REPLACEMENT_REASON"
                              @update:modelValue="changesDetected = true"
                            ></LabelSelect>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <LabelText
                              labelMd="12"
                              labelSm="12"
                              textMd="12"
                              textSm="12"
                              type="textarea"
                              label="IB16 Replacement Notes"
                              id="replacementNotes"
                              v-model="item.REPLACEMENT_COMMENT"
                              :isEditable="isEditable && !item.readOnly"
                              :counter="bearingCommentMaxLength"
                              :maxlength="bearingCommentMaxLength"
                              @update:modelValue="changesDetected = true"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </td>
                  </template>

                  <template v-slot:bottom>
                    <DataTableFooter
                      ref="bearingsFooter"
                      id="inspection_bearings_footer"
                      :items="bearings"
                      v-model="pageInspection"
                    ></DataTableFooter>
                  </template> </v-data-table></v-col
            ></v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel> </v-expansion-panels
  ></v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>

<script>
import { ref, computed, watch, nextTick, onMounted } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import {
  INSPECTION_COMMENTS,
  STRUCTURE_UNIT_TYPE,
} from "@/constants/Inspections";
import { clone } from "@/util/clone";
import IconTooltip from "@/components/common/IconTooltip.vue";
import { useConfigStore } from "@/stores/config";
import { convertToInt } from "@/composables/util";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { LOGGER } from "@/util/logger";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import { BUTTONS } from "@/constants/CommonWebConstants";
import bearing from "@/data/bearing";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import { valueInRangeRule } from "@/composables/validationRules";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { scrollToDataTable } from "@/util/scrollToElement";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "InspectionBearings",
  data() {
    return {
      headers: [
        {
          key: "data-table-expand",
          width: "1%",
        },
        {
          line1: "IB02",
          line2: "Bearing",
          line3: "Key",
          key: "BEARING_KEY",
          sortable: true,
          width: "9%",
        },
        {
          line1: "IB03",
          line2: "Record",
          line3: "Key",
          key: "RECORD_KEY",
          sortable: false,
          width: "4%",
        },
        {
          line1: "IB04",
          line2: "Bearing",
          line3: "Type",
          key: "BEARING_TYPE",
          width: "9%",
          sortable: false,
        },
        {
          line1: "IB05",
          line2: "Bearing",
          line3: "Location",
          key: "BEARING_LOCATION",
          sortable: false,
          width: "8%",
        },
        {
          line1: "IB06",
          line2: "Bearing",
          line3: "Count",
          key: "BEARING_COUNT",
          sortable: false,
          width: "8%",
        },
        {
          line1: "IB07",
          line2: "Bearing",
          line3: "Movement?",
          key: "BEARING_MOVEMENT",
          sortable: false,
          width: "8%",
        },
        {
          line1: "IB08",
          line2: "Corrosion?",
          line3: null,
          key: "BEARING_CORROSION",
          sortable: false,
          width: "8%",
        },
        {
          line1: "IB09",
          line2: "Alignment",
          line3: "Issues?",
          key: "BEARING_ALIGNMENT_ISSUES",
          width: "8%",
          sortable: false,
        },
        {
          line1: "IB10",
          line2: "Anchor",
          line3: "Bolt Issues?",
          key: "BEARING_ANCHOR_BOLT_ISSUES",
          width: "8%",
          sortable: false,
        },
        {
          line1: "IB11",
          line2: "Loss of",
          line3: "Bearing Area?",
          key: "BEARING_LOSS_OF_AREA",
          width: "8%",
          sortable: false,
        },
        {
          line1: "IB12",
          line2: "Condition",
          line3: "Rating",
          key: "CONDITION_RATING",
          sortable: true,
          width: "10%",
        },

        {
          line1: null,
          line2: "Action",
          line3: null,
          key: "action",
          sortable: false,
          width: "3%",
        },
      ],
    };
  },
  setup() {
    const allPanels = [0];
    let panel = ref(allPanels);
    const snackbarStore = useSnackbarStore();
    let snackBarId = ref("");
    const inspectionStore = useInspectionStore();
    let structure = computed(() => inspectionStore.selectedInspection);

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    const bearingRating = computed({
      get: () => {
        return structure.value.T_Bearings?.[0]?.BEARING_OVERALL_COND_RATE;
      },
      set: (value) => {
        if (structure.value.T_Bearings[0]) {
          structure.value.T_Bearings[0].BEARING_OVERALL_COND_RATE = value;
        } else {
          structure.value.T_Bearings[0] = {
            BEARING_OVERALL_COND_RATE: value,
            BRKEY: structure.value.Bridge.BRKEY,
            ID: null,
            INSPKEY: structure.value.InspEvnt.INSPKEY,
          };
        }
      },
    });
    const bearingCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.BEARING_COMMENT_MAX_LENGTH
      );
    });
    const inspCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    let bearings = ref([]);
    let expandedRows = ref([]);
    let validDetails = ref(false);
    const configStore = useConfigStore();
    const valid = ref(true);

    const bearingConditions = ref(null);

    let showAllBearings = ref(false);

    const getNewBearing = () => {
      return clone(bearing);
    };

    let detailForm = ref(null);
    let changesDetected = ref(false);
    const closeConfirm = ref(null);
    let bearingsFooter = ref(null);
    const pageInspection = ref({
      itemsPerPage: 30,
      page: 1,
    });
    let previousSort = ref([{ key: "BEARING_KEY", order: "asc" }]);
    let sortBy = ref([{ key: "BEARING_KEY", order: "asc" }]);
    async function validate() {
      bearingConditions.value.validate();
    }
    onMounted(async () => {
      await validate();
    });
    const onRowExpand = (event) => {
      //on clicking on row expansion icon - set the data to readonly.
      event.data.readOnly = true;
    };

    function getBearingLocation(value) {
      if (
        bearingLocations?.find((a) => a.value === value.BEARING_LOCATION)
          ?.title &&
        configStore
          .getReferenceValue(
            REFERENCE_TABLE.BEARING_SUBSTRUCTURE_UNIT,
            value.BEARING_SUBSTR_UNIT
          )
          ?.split(" ")[2]
      ) {
        return (
          bearingLocations?.find((a) => a.value === value.BEARING_LOCATION)
            ?.title +
          "-" +
          configStore
            .getReferenceValue(
              REFERENCE_TABLE.BEARING_SUBSTRUCTURE_UNIT,
              value.BEARING_SUBSTR_UNIT
            )
            .split(" ")[2]
        );
      } else {
        return "";
      }
    }

    const sort = (arr) => {
      arr.value = arr.value?.sort(function (a, b) {
        if (a.BEARING_KEY == b.BEARING_KEY) {
          return convertToInt(b.RECORD_KEY) - convertToInt(a.RECORD_KEY);
        } else {
          return a.BEARING_KEY - b.BEARING_KEY;
        }
      });
    };

    const isNotCurrent = (details) => {
      //check for replacements
      const maxRecordkey = Math.max(
        ...bearings.value
          .filter((a) => a.BEARING_KEY == details.BEARING_KEY)
          .map((a) => a.RECORD_KEY)
      );
      return details.RECORD_KEY != maxRecordkey;
    };

    const isReplacement = (details) => {
      //check if if replacement
      const minRecordkey = Math.min(
        ...bearings.value
          .filter((a) => a.BEARING_KEY == details.BEARING_KEY)
          .map((a) => a.RECORD_KEY)
      );
      return details.RECORD_KEY != minRecordkey;
    };

    const cloneCurrentInspectionBearings = () => {
      let bearingsClone = clone(structure.value.T_Bearings_Details);

      if (!bearingsClone) {
        //remove null values from array(if any)
        bearingsClone = bearingsClone?.filter((a) => !!a);
      }

      const currentInspectionBearingIndex =
        structure.value?.T_Bearings?.findIndex(
          (a) => a?.INSPKEY == structure.value.InspEvnt.INSPKEY
        );

      bearingsClone = bearingsClone?.filter(
        (a) =>
          a?.ID == structure.value.T_Bearings[currentInspectionBearingIndex]?.ID
      );

      return bearingsClone;
    };
    const getAllBearingDetails = () => {
      expandedRows.value = [];
      bearings.value = cloneCurrentInspectionBearings();
      //set unique key for toggle expansion
      for (const element of bearings.value) {
        element.UNIQUE_KEY = element.BEARING_KEY + "-" + element.RECORD_KEY;
        element.readOnly = true;
      }
      sort(bearings);
    };

    const getActiveBearingDetails = () => {
      expandedRows.value = [];
      bearings.value = cloneCurrentInspectionBearings();
      let activeBearings = [];

      //set unique key for toggle expansion
      if (bearings.value) {
        for (const element of bearings.value) {
          if (element?.BEARING_KEY && element?.RECORD_KEY) {
            element.UNIQUE_KEY =
              element?.BEARING_KEY + "-" + element?.RECORD_KEY;
            element.readOnly = true;
            if (!isNotCurrent(element)) {
              activeBearings.push(element);
            }
          }
        }
      }
      bearings.value = activeBearings;
      sort(bearings);
    };

    const hasReplacedBearingDetails = () => {
      const clonedBearings = cloneCurrentInspectionBearings();

      if (clonedBearings) {
        return clonedBearings.some(
          (element) =>
            element.BEARING_KEY && element.RECORD_KEY && isNotCurrent(element)
        );
      }
      showAllBearings.value = false;
      return false;
    };

    getActiveBearingDetails();

    const addNewBearing = () => {
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.BEARINGS_DATATABLE,
      });
      let newBearing = getNewBearing();
      if (structure.value.T_Bearings[0]) {
        const currentInspectionBearingIndex =
          structure.value?.T_Bearings?.findIndex(
            (a) => a?.INSPKEY == structure.value.InspEvnt.INSPKEY
          );

        newBearing.ID =
          structure.value.T_Bearings[currentInspectionBearingIndex]?.ID;
      } else {
        newBearing.ID = null;
      }
      newBearing.BEARING_KEY = getNextBearingKey() + 1;
      newBearing.RECORD_KEY = "1";
      newBearing.isAdd = true;
      newBearing.UNIQUE_KEY =
        newBearing.BEARING_KEY + "-" + newBearing.RECORD_KEY;
      newBearing.readOnly = false;
      bearings.value.unshift(newBearing);
      previousSort.value = sortBy.value.map((x) => x);
      sortBy.value = [];
      expandedRows.value = [newBearing];
      scrollToDataTable("bearings-data-table");

      bearingsFooter.value?.setPageValue(1);
      nextTick(() => {
        detailForm.value.validate();
      });
    };

    const closeDetail = () => {
      if (isEditable.value && changesDetected.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              changesDetected.value = false;
              getBearingDetails();
              expandedRows.value = [];
              inspectionStore.setAddingOrEditing(false);
              snackbarStore.removeMessage(snackBarId.value);
              sortBy.value = previousSort.value?.map((x) => x);
            }
          });
      } else {
        getBearingDetails();
        expandedRows.value = [];
        inspectionStore.setAddingOrEditing(false);
        snackbarStore.removeMessage(snackBarId.value);
        sortBy.value = previousSort.value?.map((x) => x);
      }
    };

    const saveDetail = (detail) => {
      detailForm.value
        .validate()
        .then(() => {
          if (validDetails.value) {
            inspectionStore.setShowValidationSnackbar(false);
            if (!structure.value?.T_Bearings_Details?.[0]) {
              //remove null values from array(if any)
              structure.value.T_Bearings_Details =
                structure.value.T_Bearings_Details?.filter((a) => !!a);
            }

            changesDetected.value = false;
            if (detail.isAdd) {
              if (!structure.value.T_Bearings[0]) {
                const {
                  InspEvnt: { INSPKEY, BRKEY },
                } = structure.value;
                structure.value.T_Bearings.push({
                  BEARING_OVERALL_COND_RATE: "",
                  BRKEY,
                  ID: null,
                  INSPKEY,
                });
              }
              structure.value.T_Bearings_Details.push(
                convertToBearingDetailsObject(detail)
              );
            } else {
              //update existing detail
              const updateIndex = structure.value.T_Bearings_Details.findIndex(
                (a) =>
                  a.BEARING_KEY === detail.BEARING_KEY &&
                  a.RECORD_KEY === detail.RECORD_KEY
              );
              structure.value.T_Bearings_Details.splice(
                updateIndex,
                1,
                convertToBearingDetailsObject(detail)
              );
            }

            //if all required fields entered - close the expanded row
            expandedRows.value = [];
            inspectionStore.setAddingOrEditing(false);
            snackbarStore.removeMessage(snackBarId.value);
            getBearingDetails();
            sortBy.value = previousSort.value?.map((x) => x);
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => {
          LOGGER.logException(err);
          changesDetected.value = false;
        });
    };

    const convertToBearingDetailsObject = (details) => {
      let bearingDetailsObject = getNewBearing();
      bearingDetailsObject.ID = details.ID;
      bearingDetailsObject.BEARING_KEY = details.BEARING_KEY;
      bearingDetailsObject.RECORD_KEY = details.RECORD_KEY;
      bearingDetailsObject.CONDITION_RATING = details.CONDITION_RATING;
      bearingDetailsObject.ALLIGNMENT_ISSUES = details.ALLIGNMENT_ISSUES;
      bearingDetailsObject.CREATEDATETIME = details.CREATEDATETIME;
      bearingDetailsObject.CREATEUSERKEY = details.CREATEUSERKEY;
      bearingDetailsObject.ANCHOR_BOLT = details.ANCHOR_BOLT;
      bearingDetailsObject.BEARING_AREA = details.BEARING_AREA;
      bearingDetailsObject.ECMS_ID = details.ECMS_ID;
      bearingDetailsObject.BEARING_COMMENT = details.BEARING_COMMENT;
      bearingDetailsObject.BEARING_COUNT = details.BEARING_COUNT;
      bearingDetailsObject.BEARING_LOCATION = details.BEARING_LOCATION;
      bearingDetailsObject.BEARING_MOVEMENT = details.BEARING_MOVEMENT;
      bearingDetailsObject.BEARING_SUBSTR_UNIT = details.BEARING_SUBSTR_UNIT;
      bearingDetailsObject.BEARING_TYPE = details.BEARING_TYPE;
      bearingDetailsObject.CORROSION = details.CORROSION;
      bearingDetailsObject.INSTALL_DATE = details.INSTALL_DATE;
      bearingDetailsObject.MODTIME = new Date().toISOString();
      bearingDetailsObject.REPLACEMENT_COMMENT = details.REPLACEMENT_COMMENT;
      bearingDetailsObject.REPLACEMENT_REASON = details.REPLACEMENT_REASON;
      bearingDetailsObject.USERKEY = details.USERKEY;
      return bearingDetailsObject;
    };

    const editDetail = (detail) => {
      previousSort.value = sortBy.value.map((x) => x);

      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.BEARINGS_DATATABLE,
      });
      detail.readOnly = false;
      //if all required fields entered - close the expanded row
      expandedRows.value = [detail];
      nextTick(() => {
        detailForm.value.validate();
      });
      sortBy.value = [];
      moveEditableElementToTop(detail);
      bearingsFooter.value?.setPageValue(1);
    };

    const moveEditableElementToTop = (detail) => {
      const index = bearings.value.findIndex(
        (a) =>
          a.BEARING_KEY === detail.BEARING_KEY &&
          a.RECORD_KEY === detail.RECORD_KEY
      );
      let editableElement = bearings.value[index];
      //then remove that element and use unshift
      bearings.value.splice(index, 1);
      bearings.value.unshift(editableElement);
    };

    const replaceDetail = (detail) => {
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.BEARINGS_DATATABLE,
      });
      previousSort.value = sortBy.value.map((x) => x);
      let newBearing = getNewBearing();
      newBearing.isAdd = true;
      newBearing.isReplacing = true;
      newBearing.ID = detail.ID || null;
      newBearing.BEARING_KEY = detail.BEARING_KEY;
      newBearing.RECORD_KEY = (
        getNextRecordKey(detail.BEARING_KEY) + 1
      ).toString();
      newBearing.BEARING_SUBSTR_UNIT = detail.BEARING_SUBSTR_UNIT;
      newBearing.BEARING_LOCATION = detail.BEARING_LOCATION;
      newBearing.UNIQUE_KEY =
        newBearing.BEARING_KEY + "-" + newBearing.RECORD_KEY;
      newBearing.readOnly = false;
      bearings.value.push(newBearing);
      expandedRows.value = [newBearing];
      sort(bearings);
      nextTick(() => {
        detailForm.value.validate();
      });
    };

    const deleteDetail = (detail) => {
      const deleteIndex = structure.value.T_Bearings_Details.findIndex(
        (a) =>
          a.BEARING_KEY === detail.BEARING_KEY &&
          a.RECORD_KEY === detail.RECORD_KEY
      );
      if (deleteIndex > -1) {
        structure.value.T_Bearings_Details.splice(deleteIndex, 1);
        expandedRows.value = [];
        getBearingDetails();
      }
    };

    const getNextBearingKey = () => {
      if (bearings.value) {
        return Math.max(
          ...bearings.value.map((bearingDetail) =>
            convertToInt(bearingDetail?.BEARING_KEY ?? 0)
          ),
          0
        );
      } else {
        return 0;
      }
    };

    const getNextRecordKey = (bearingKey) => {
      let bearingDetails = bearings.value.filter(
        (bearingDetail) => bearingDetail.BEARING_KEY == bearingKey
      );
      return Math.max(
        ...bearingDetails.map((bearingDetail) =>
          convertToInt(bearingDetail.RECORD_KEY)
        )
      );
    };

    const isExpanded = (details) => {
      return (
        expandedRows.value?.findIndex(
          (a) =>
            a.BEARING_KEY == details.BEARING_KEY &&
            a.RECORD_KEY === details.RECORD_KEY
        ) >= 0
      );
    };

    const bearingLocations = inspectionStore
      .getFilteredStructureUnits(
        [STRUCTURE_UNIT_TYPE.ABUTMENT, STRUCTURE_UNIT_TYPE.PIER],
        true
      )
      ?.map((b) => ({
        title: b.STRUNITDESCRIPTION
          ? `${b.STRUNITDESCRIPTION}-${b.STRUNITLABEL}`
          : `${configStore.getReferenceValue(
              REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
              b.STRUNITTYPE,
              true
            )}-${b.STRUNITLABEL}`,
        value: b.STRUNITKEY.toString(),
      }))
      .sort((a, b) => a.title.localeCompare(b.title));

    const rowClass = (data) => {
      return isNotCurrent(data) && !data.isAdd ? "rowReplaced" : "rowCurrent";
    };

    const anyBearingDetailEditable = computed(() => {
      return expandedRows.value?.find((element) => !element.readOnly);
    });
    const getBearingDetails = () => {
      if (showAllBearings.value) {
        getAllBearingDetails();
      } else {
        getActiveBearingDetails();
      }
    };

    const toggleRow = (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (d) => d.RECORD_KEY == detail.RECORD_KEY
        );
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.T_Bearings,
        structure.value.T_Insp_Comment,
        structure.value.T_Bearings_Details,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      isReplacement,
      getBearingLocation,
      panel,
      REFERENCE_TABLE,
      isEditable,
      structure,
      bearingRating,
      INSPECTION_COMMENTS,
      bearings,
      onRowExpand,
      expandedRows,
      addNewBearing,
      validDetails,
      isExpanded,
      configStore,
      closeDetail,
      saveDetail,
      editDetail,
      replaceDetail,
      deleteDetail,
      bearingLocations,
      bearingCommentMaxLength,
      inspCommentMaxLength,
      rowClass,
      anyBearingDetailEditable,
      detailForm,
      changesDetected,
      getAllBearingDetails,
      getActiveBearingDetails,
      showAllBearings,
      isNotCurrent,
      closeConfirm,
      BUTTONS,
      inspectionStore,
      INSPECTION_MESSAGES,
      getBearingDetails,
      toggleRow,
      hasReplacedBearingDetails,
      valueInRangeRule,
      pageInspection,
      bearingsFooter,
      sortBy,
      bearingConditions,
      valid,
      validate,
      toggleAllPanels,
    };
  },
  components: {
    DataTableHeader,
    LabelSelect,
    CommentTypeTextField,
    LabelText,
    IconTooltip,
    ConfirmDialog,
    DataTableFooter,
    ExpandCollapseToggle,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.alignCenter {
  text-align: center;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}
</style>
